<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.name' placeholder='输入商品名称'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        :data="tableData"
        highlight-current-row
        @current-change='selectCurrentRow'
        style="width: 95%">
            <el-table-column type='index' label="序号" width="60" align='center'></el-table-column>
            <el-table-column prop='a' label="课程编号" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='b' label="课程名称" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='c' label="岗位教材" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='d' label="行业教材" width="" align='center'></el-table-column>
            <el-table-column prop='e' label="价格" width="" align='center'></el-table-column>
            <el-table-column prop='f' label="创建人" width="" align='center'></el-table-column>
            <el-table-column prop='g' label="创建时间" width="" align='center' show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.total">
        </el-pagination>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="isAdd?'新增':'编辑'"
        :visible.sync="dialogVisible"
        width="45%"
        :close-on-click-modal='false'
        @close='handleClose'>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="130px">
            <el-form-item label="课程名称：" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label='课程底图：' prop='pic'>
                <!-- <el-upload
                    action="#"
                    list-type="picture-card"
                    :auto-upload="false">
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url" alt=""
                        >
                        </div>
                </el-upload> -->
            </el-form-item>
            <el-form-item label='关联岗位教材：' prop='book'>
                <el-select v-model="form.book" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='价格：' prop='price'>
                <el-input v-model='form.price' ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
export default {
    components:{Toolbar},
    data(){
        // 验证价格
        let checkPrice = (rule,value,callback) =>{
            var reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
            if(reg.test(value)){
                return callback()
            }
            return callback(new Error('请输入正确的价格'))
        }
        return {
            buttonList:[],
            filters:{
                name:''
            },
            tableData:[
                {a:'10001',b:'消防安全责任人',c:'单位消防安全责任人',d:'教材',e:'500',f:'',g:'2021/6/15 16:46:30'}
            ],
            currentRow:{},
            pages:{
              pageSize:20,
              pageIndex:1,
              total:0
            },
            isAdd:true, // 是否新增、编辑
            dialogVisible:false,
            options:5,
            form:{
                name:'',
                book:'',
                price:''
            },
            rules:{
                name:[{required:true,message:'请输入课程名称',trigger:'blur'}],
                bool:[{required:true,message:'请选择教材',trigger:'change'}],
                price:[
                    {required:true,message:'请输入价格',trigger:'blur'},
                    {validator:checkPrice,trigger:'blue'}
                ]
            }
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 选中当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
        // 分页
        handleSizeChange(value){
          this.pages.pageSize = value
          this.getData()
        },
        handleCurrentChange(value){
          this.pages.pageIndex = value
          this.getData()
        },
        // 查询
        find(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        add(){
            this.isAdd = true
            this.dialogVisible = true
        },
        // 编辑
        edit(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message.warning('请选择要编辑的一行数据')
            }
            this.isAdd = false
            this.form = {...row}
            this.dialogVisible = true
        },
        // 表单提交
        submit(){
            this.$refs['formRef'].validate((valid) => {
                if(valid){
                    if(this.isAdd){
                        var params = {...this.form}
                        this.addSubmit(params)
                    }else{
                        var params = {...this.form}
                        this.editSubmit(params)
                    }
                }else{
                    return
                }
            })
        },
        addSubmit(params){
            // 'xxx'(params).then(res => {

            // }).catch(() =>{})
        },
        editSubmit(params){
            // 'xxx'(params).then(res => {

            // }).catch(() =>{})
        },
        // 删除
        delete(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message.warning('请选择要删除的一行数据')
            }
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 'xxx'().then(res => {

                // }).catch(() => {})
            }).catch(() => {return})           
        },
        // 对话框关闭，清空表单内容
        handleClose(){

        },
        // 获取列表数据
        getData(){
            var params = {}
            // 'xxx'(params).then(res =>{

            // }).catch(() =>{})
        },
    },    
    mounted(){
        this.getData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>